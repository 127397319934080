.login-form-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-form-container form {
    display: flex;
    flex-direction: column;
}

.login-form-container input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-form-container button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.login-form-container button:hover {
    background-color: #0056b3;
}

.login-form-container .error-message {
    color: red;
}

.login-form-container p {
    margin-top: 10px;
    text-align: center;
}
