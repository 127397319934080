/* UserInfoStyles.css */

.user-info-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.user-info-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.user-info-container p {
    font-size: 16px;
    margin-bottom: 10px;
    color: #555;
}

.user-info-container input[type="text"],
.user-info-container input[type="number"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.user-info-container button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: 10px;
    margin-bottom: 10px; /* Добавляем отступ снизу */
}

.user-info-container button:hover {
    background-color: #0056b3;
}

.user-info-container a {
    text-decoration: none;
    color: #007bff;
}

.user-info-container a:hover {
    text-decoration: underline;
}
