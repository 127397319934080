/* PurchaseTabStyles.css */

.purchase-tab-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.purchase-tab-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.purchase-tab-container p {
    font-size: 16px;
    margin-bottom: 10px;
}

.purchase-tab-container form {
    margin-bottom: 20px;
}

.purchase-tab-container label {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
    color: #555;
}

.purchase-tab-container select,
.purchase-tab-container input[type="number"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.purchase-tab-container button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.purchase-tab-container button:hover {
    background-color: #0056b3;
}

.purchase-tab-container button + button {
    margin-left: 10px;
}

.purchase-tab-container a {
    text-decoration: none;
    color: #007bff;
    margin-left: 10px;
}

.purchase-tab-container a:hover {
    text-decoration: underline;
}
